import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import AppContext from '../store/AppContext';
import { Alert } from '@mui/material';
import { checkSignupUserLogin } from '../redux/features/signup-student/student.slice';
import Loading from '../components/Loading';
var qs = require('qs');

export default function SignupStudentLogin({props}: any) {

    const dispatch = useAppDispatch();
    const appContext = useContext(AppContext);
    const [ssoError, setSSOError] = useState("N")

    const signupUser = useAppSelector((state : any) => state.signupUser)
    const hostname = window && window.location && window.location.hostname;

    useEffect(() => {
        const searchParams = qs.parse(window.location.search);
        dispatch(checkSignupUserLogin({
            classCode: searchParams["classCode"],
            userId: searchParams["?userId"]
        }))
    }, [])

    useEffect(() => {
        // console.log('classlinkUser ', signupUser,signupUser.status)
        // if(signupUser.status === 'succeeded' && signupUser.signupUserProfile.status === 'active') {
        //     const userProfile = signupUser.signupUserProfile
        //     if((userProfile.role).toLowerCase() === "teacher"){
        //         const userDetails = { ...userProfile, selectedSection: userProfile?.sections ? userProfile?.sections[0] : {} }
        //         appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
        //         if(hostname.includes('dev')) {
        //             window.open("https://dev.aautischool.com/teacher","_self")
        //         } else if(hostname.includes('qa')) {
        //             window.open("https://qa.aautischool.com/teacher","_self")
        //         } else if(hostname.includes('prod')) {
        //             window.open("https://prod.aautischool.com/teacher","_self")
        //         } else {
        //             window.open("https://www.aautischool.com/teacher","_self")
        //         }
        //     }else if((userProfile.role).toLowerCase() === "student"){
        //         appContext?.setStudentUserDetails(userProfile, "studentUserDetails");
        //         console.log(userProfile,"userProfileuserProfileuserProfile")
        //         if(hostname.includes('dev')) {
        //             window.open("https://dev.aautischool.com/student","_self")
        //         } else if(hostname.includes('qa')) {
        //             window.open("https://qa.aautischool.com/student","_self")
        //         } else if(hostname.includes('prod')) {
        //             window.open("https://prod.aautischool.com/student","_self")
        //         } 
        //         // else {
        //         //     window.open("https://www.aautischool.com/student","_self")
        //         // }
        //     }else if((userProfile.role).toLowerCase() === "parent"){
        //         appContext?.setParentUserDetails(userProfile, "parentUserDetails");
        //         if(hostname.includes('dev')) {
        //             window.open("https://dev.aautischool.com/parent","_self")
        //         } else if(hostname.includes('qa')) {
        //             window.open("https://qa.aautischool.com/parent","_self")
        //         } else if(hostname.includes('prod')) {
        //             window.open("https://prod.aautischool.com/parent","_self")
        //         } else {
        //             window.open("https://www.aautischool.com/parent","_self")
        //         }
        //     }
        // }  
        if (signupUser.status === 'loading') {
            <Loading/>
        }else  if(signupUser.status === 'succeeded' && signupUser.signupUserProfile.status === 'active') {
            const userProfile = signupUser.signupUserProfile
            if((userProfile.role).toLowerCase() === "teacher"){
                const userDetails = { ...userProfile, selectedSection: userProfile?.sections ? userProfile?.sections[0] : {} }
                appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
                if(hostname.includes('dev')) {
                    window.open("https://dev.aautischool.com/teacher","_self")
                } else if(hostname.includes('qa')) {
                    window.open("https://qa.aautischool.com/teacher","_self")
                } else if(hostname.includes('prod')) {
                    window.open("https://prod.aautischool.com/teacher","_self")
                } else {
                    window.open("https://www.aautischool.com/teacher","_self")
                }
            }else if((userProfile.role).toLowerCase() === "student"){
                appContext?.setStudentUserDetails(userProfile, "studentUserDetails");
                if(hostname.includes('dev')) {
                    window.open("https://dev.aautischool.com/student","_self")
                } else if(hostname.includes('qa')) {
                    window.open("https://qa.aautischool.com/student","_self")
                } else if(hostname.includes('prod')) {
                    window.open("https://prod.aautischool.com/student","_self")
                } 
                else {
                    window.open("https://www.aautischool.com/student","_self")
                }
            }else if((userProfile.role).toLowerCase() === "parent"){
                appContext?.setParentUserDetails(userProfile, "parentUserDetails");
                if(hostname.includes('dev')) {
                    window.open("https://dev.aautischool.com/parent","_self")
                } else if(hostname.includes('qa')) {
                    window.open("https://qa.aautischool.com/parent","_self")
                } else if(hostname.includes('prod')) {
                    window.open("https://prod.aautischool.com/parent","_self")
                } else {
                    window.open("https://www.aautischool.com/parent","_self")
                }
            }
        }else if (signupUser.status === "failed"){
            setSSOError("Y")
        }
    }, [signupUser])

    const error = <Alert severity="error">We are unable to login now — check with your admin!</Alert>
    
    return(
        <div>
            {ssoError === 'Y' ? error : ''}
        </div>
    )
}