export const environment = (): string => { 
    const hostname = window && window.location && window.location.hostname;
    console.log('hostname  ', hostname);
    if(hostname.includes('dev')) {
        return "dev"
    } else if(hostname.includes('qa')) {
        return "qa"
    } else if(hostname.includes('local') || hostname.includes('redesign')) {
        return "dev"
    } else {
       return "prod";
    }
};

export const getEnvironmentUrl = (url?: string) => {
    const env = environment();
    // if(env==="dev" || env==="qa") {
        if (url && url.includes('async-svc')) {
            return `https://aauti-admin-services-${env}.aauti-backend.com`
        } else if(url && url.includes('core-svc')) {
            return `https://aauti-core-services-${env}.aauti-backend.com`
        } else if(url && url.includes('comm-svc')) {
            return `https://aauti-comm-services-${env}.aauti-backend.com`
        }else if (url && url.includes('content-svc')){
            return `https://aauti-content-services-${env}.aauti-backend.com`
        }
    // }
    return `https://${env}.aauti-backend.com`
  }
  
  export const MIX_PANEL_TOKEN = 'f23381f7fb6038bffa2be85d37aa7041'
